<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDeleteMember" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div v-if="StoreObj.action">Delete {{ StoreObj.user_role.toLowerCase() }}</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDeleteMemberEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" :color="'red'" v-text="'mdi-delete'"></v-icon>
          <div>Are you sure you want to remove {{ StoreObj.user_email_id }} ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogDeleteMemberEmit(1)"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="dialogMemberMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { removeUsersFromProject } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    getEachProjectDetails: Object,
    dialogDeleteMember: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
    },
    emails: [],
    loading: false,
    project_details: {},
    SnackBarComponent: {},
  }),
  watch: {
    dialogDeleteMember(value) {
      if (value == true) {
        this.project_details = { ...this.$store.getters.get_current_project_details };
      }
    },
  },
  methods: {
    async dialogMemberMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
        user_role: this.StoreObj.user_role,
        [this.StoreObj.user_role == "TESTER" ? "testers_email" : this.StoreObj.user_role == "DEVELOPER" ? "developers_email" : "observers_email"]: [this.StoreObj.user_email_id],
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(removeUsersFromProject, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.RemoveUsersFromProject);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogDeleteMemberEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogDeleteMemberEmit(Toggle) {
      this.emails = [];
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
