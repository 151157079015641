<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogChangeOwnership">
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div v-text="'Transfer Ownership'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeOwnershipEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0 pt-4">
          <v-form ref="form">
            <v-text-field
              dense
              multiple
              outlined
              class="FontSize field_height field_label_size"
              label="Enter Email ID"
              v-model="new_owner_email_id"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
            >
            </v-text-field>
            <v-checkbox dense class="ma-0 pa-0" v-model="is_observer" label="Continue As An Observer ?"></v-checkbox>
          </v-form>
          <v-card outlined class="pa-2">
            <v-card-actions class="pa-0">
              <div class="fontSizeSmall font-weight-bold">
                <v-icon small color="red">mdi-alert</v-icon>
                Are you sure you want to transfer ownership to the {{ new_owner_email_id }} ? This Change cannot be undone.
              </div>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-actions class="pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogChangeOwnershipEmit(1)">Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateFormMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { transferOwnership } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogChangeOwnership: Boolean,
    StoreObj: Object,
    item: String,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    is_observer: false,
    new_owner_email_id: "",
    SnackBarComponent: {},
  }),
  watch: {
    dialogChangeOwnership(value) {
      if (value == true) {
        this.is_observer = false;
        this.new_owner_email_id = this.StoreObj.created_by;
      }
    },
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.changeOwnershipMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select An Email ID",
        };
      }
    },
    async changeOwnershipMethod() {
      var inputParams = {
        old_owner_email_id: this.$store.getters.get_user_email,
        project_id: this.StoreObj.project_id,
        new_owner_email_id: this.new_owner_email_id.trim(),
        is_observer: this.is_observer ? this.is_observer : false,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(transferOwnership, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.TransferOwnership);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogChangeOwnershipEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
