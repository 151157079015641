<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogChangeDeveloperLead">
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div v-text="'Change Developer Lead'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeDeveloperLeadEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0 pt-4">
          <v-form ref="form">
            <v-text-field
              dense
              multiple
              outlined
              class="FontSize field_height field_label_size"
              label="Enter Email ID"
              v-model="developer_lead_email_id"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogChangeDeveloperLeadEmit(1)">Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateFormMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { editProject } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogChangeDeveloperLead: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    developer_lead_email_id: "",
    SnackBarComponent: {},
  }),
  watch: {
    async dialogChangeDeveloperLead(value) {
      if (value == true) {
        this.developer_lead_email_id = this.StoreObj.developer_lead_email_id;
      }
    },
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createProjectMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select An Email ID",
        };
      }
    },
    async createProjectMethod() {
      var mutationname = editProject;
      var mutationresult = "EditProject";
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.StoreObj.project_id,
        project_name: this.StoreObj.project_name,
        project_description: this.StoreObj.project_description,
        sub_systems: this.StoreObj.sub_systems,
        project_website_url: this.StoreObj.project_website_url && this.StoreObj.project_website_url != "" ? this.StoreObj.project_website_url : undefined,
        developer_lead_email_id: this.developer_lead_email_id.trim(),
        tester_lead_email_id: this.StoreObj.tester_lead_email_id.trim(),
        mfa_enabled: this.StoreObj.mfa_enabled,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogChangeDeveloperLeadEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogChangeDeveloperLeadEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
