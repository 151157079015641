import { listUsersOfProject } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
export const GetAllUsersDetails = {
  mixins: [GetEachProjectDetails],
  data() {
    return {
      GetAllUserDetailsList: [],
      overlay: false,
      noDataText: "",
    };
  },
  methods: {
    async GetAllUsersDetailsMethod(user_role, route, project_id) {
      try {
        this.GetAllUserDetailsList = [];
        this.overlay = true;
        let project_details = {};
        if (this.$route.name != "ProjectPage") {
          await this.GetEachProjectDetailsMethod();
          project_details = this.$store.getters.get_current_project_details;
        }
        let result = await API.graphql(
          graphqlOperation(listUsersOfProject, {
            input: {
              project_id: route == "DIALOG" ? project_id : this.$store.getters.get_current_project_details.project_id,
              user_role,
            },
          })
        );
        this.GetAllUserDetailsList = JSON.parse(result.data.ListUsersOfProject).map((user) => {
          let userObject = {};
          userObject.user_email_id = user;
          if (this.$route.name != "ProjectPage") {
            userObject.user_role =
              project_details.developer_lead_email_id == userObject.user_email_id && project_details.tester_lead_email_id != userObject.user_email_id
                ? "DEV_LEAD"
                : project_details.developer_lead_email_id != userObject.user_email_id && project_details.tester_lead_email_id == userObject.user_email_id
                ? "TEST_LEAD"
                : project_details.developer_lead_email_id == userObject.user_email_id && project_details.tester_lead_email_id == userObject.user_email_id
                ? "TEST_LEAD_&_DEV_LEAD"
                : "";
            userObject.is_approver =
              (project_details.issue_approvers && project_details.issue_approvers.includes(user) == true) || project_details.tester_lead_email_id == userObject.user_email_id ? true : false;
            userObject.all_roles = [];
            userObject.is_project_owner = project_details.created_by == userObject.user_email_id ? true : false;
            if (project_details.created_by == user) {
              userObject.all_roles.push("OWNER");
            }
            if (project_details.tester_lead_email_id == user) {
              userObject.all_roles.push("TEST LEAD");
            }
            if (project_details.developer_lead_email_id == user) {
              userObject.all_roles.push("DEV LEAD");
            }
            if (project_details.developers_email.includes(user)) {
              userObject.all_roles.push("DEVELOPER");
            }
            if (project_details.testers_email.includes(user)) {
              userObject.all_roles.push("TESTER");
            }
            if (project_details.observers_email.includes(user)) {
              userObject.all_roles.push("OBSERVER");
            }
          }
          return userObject;
        });
        console.log("Check GetAllUserDetailsList", this.GetAllUserDetailsList);
        if (this.GetAllUserDetailsList.length == 0) {
          this.noDataText = "No User Found";
        }
        this.$forceUpdate();
        this.overlay = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.noDataText = error.errors[0].message;
        this.overlay = false;
        console.log(error);
      }
    },
  },
};
