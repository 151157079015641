<template>
  <div>
    <Overlay :overlay="overlay" />
    <ChangeOwnerShip :StoreObj="getEachProjectDetails" :dialogChangeOwnership="dialogChangeOwnership" @clicked="dialogChangeOwnershipEmit" />
    <WithDrawnOwnershipRequest :dialogWithdrawOwnership="dialogWithdrawOwnership" @clicked="dialogWithdrawOwnershipEmit" />
    <ChangeDeveloperLead :StoreObj="getEachProjectDetails" :dialogChangeDeveloperLead="dialogChangeDeveloperLead" @clicked="dialogChangeDeveloperLeadEmit" />
    <ChangeTestLead :StoreObj="getEachProjectDetails" :dialogChangeTestLead="dialogChangeTestLead" @clicked="dialogChangeTestLeadEmit" />
    <v-card class="elevation-0">
      <v-row class="" v-if="Object.keys(getEachProjectDetails).length != 0">
        <v-col cols="12" xs="12" sm="12" md="12" class="font-weight-bold fontSize py-0">
          Owner

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-if="getEachProjectDetails.created_by == $store.getters.get_user_email"
                x-small
                icon
                size="50px"
                class="ma-2"
                color="primary"
                @click="checkItem('OWNER')"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Transfer Ownership</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-if="getEachProjectDetails.created_by == $store.getters.get_user_email && getEachProjectDetails.ownership_transfered_to"
                x-small
                icon
                size="50px"
                class="ma-2"
                color="primary"
                @click="checkItem('WITHDRAW')"
              >
                <v-icon small>mdi-account-minus</v-icon>
              </v-btn>
            </template>
            <span>Withdraw Ownership Request</span>
          </v-tooltip>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 fontSize"
          ><div>{{ getEachProjectDetails.created_by }}</div>
          <div class="fontSizeSmall font-weight-bold" v-if="getEachProjectDetails.ownership_transfer == 'TRUE'">
            (Ownership Transfer Request Pending On {{ getEachProjectDetails.ownership_transfered_to }})
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="font-weight-bold fontSize py-2">
          Developer Lead
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-if="getEachProjectDetails.developer_lead_email_id == $store.getters.get_user_email || getEachProjectDetails.created_by == $store.getters.get_user_email"
                x-small
                icon
                size="50px"
                class="ma-2"
                color="primary"
                @click="checkItem('DEV_LEAD')"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Change Developer Lead</span>
          </v-tooltip>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 fontSize">
          {{ getEachProjectDetails.developer_lead_email_id }}
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="font-weight-bold fontSize py-0">
          Test Lead
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-if="getEachProjectDetails.tester_lead_email_id == $store.getters.get_user_email || getEachProjectDetails.created_by == $store.getters.get_user_email"
                x-small
                icon
                size="50px"
                class="ma-2"
                color="primary"
                @click="checkItem('TEST_LEAD')"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Change Test Lead</span>
          </v-tooltip>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 fontSize">
          {{ getEachProjectDetails.tester_lead_email_id }}
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import ChangeOwnerShip from "@/components/ProjectDetails/Dialogs/ChangeOwnerShip.vue";
import ChangeDeveloperLead from "@/components/ProjectDetails/Dialogs/ChangeDeveloperLead.vue";
import ChangeTestLead from "@/components/ProjectDetails/Dialogs/ChangeTestLead.vue";
import WithDrawnOwnershipRequest from "@/components/ProjectDetails/Dialogs/WithDrawnOwnershipRequest.vue";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
export default {
  components: {
    Overlay,
    ChangeOwnerShip,
    WithDrawnOwnershipRequest,
    ChangeDeveloperLead,
    ChangeTestLead,
  },
  mixins: [GetEachProjectDetails],
  data: () => ({
    search: "",
    overlay: false,
    dialogDeleteMember: false,
    dialogCreateMember: false,
    dialogAddRemoveApprover: false,
    dialogChangeOwnership: false,
    dialogWithdrawOwnership: false,
    dialogChangeDeveloperLead: false,
    dialogChangeTestLead: false,
    user_role: "TESTER",
    user_role_items: ["TESTER", "DEVELOPER", "OBSERVER"],

    my_roles: [],
    StoreObj: {},
    windowHeight: 0,
    project_details: {},
  }),
  async mounted() {
    this.my_roles = await this.$store.getters.get_current_project_details.roles;
    await this.GetEachProjectDetailsMethod();
    this.windowHeight = window.innerHeight - 160;
  },
  methods: {
    dialogWithdrawOwnershipEmit(Toggle) {
      this.dialogWithdrawOwnership = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.dialogChangeOwnership = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    getHeaders() {
      switch (this.user_role) {
        case "TESTER":
          if (this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("OWNER") == true) return this.memberHeaders;
          else return this.memberHeaders.filter((header) => header.value != "Action");
        case "DEVELOPER":
          if (this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("OWNER") == true) return this.memberHeaders.filter((header) => header.value != "is_approver");
          else return this.memberHeaders.filter((header) => header.value != "Action" && header.value != "is_approver");
        case "OBSERVER":
          if (this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("OWNER") == true)
            return this.memberHeaders.filter((header) => header.value != "is_approver");
          else return this.memberHeaders.filter((header) => header.value != "Action" && header.value != "is_approver");
      }
    },
    checkCondition() {
      if (this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("OWNER") == true) return true;
      else return false;
    },
    async dialogAddRemoveApproverEmit(Toggle) {
      this.dialogAddRemoveApprover = false;
      if (Toggle == 2) {
        await this.GetEachProjectDetailsMethod();
      }
    },
    async dialogDeleteMemberEmit(Toggle) {
      this.dialogDeleteMember = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    checkItem(ACTION) {
      this.StoreObj.action = ACTION;
      switch (ACTION) {
        case "TESTER":
          this.dialogCreateMember = true;
          break;
        case "DEVELOPER":
          this.dialogCreateMember = true;
          break;
        case "OBSERVER":
          this.dialogCreateMember = true;
          break;
        case "OWNER":
          this.dialogChangeOwnership = true;
          break;
        case "WITHDRAW":
          this.dialogWithdrawOwnership = true;
          break;
        case "DEV_LEAD":
          this.dialogChangeDeveloperLead = true;
          break;
        case "TEST_LEAD":
          this.dialogChangeTestLead = true;
          break;
        case "APPROVER":
          this.dialogAddRemoveApprover = true;
          break;
      }
    },
    deleteItem(ACTION) {
      this.StoreObj.action = ACTION;
      switch (ACTION) {
        case "TESTER":
          this.dialogDeleteMember = true;
          break;
        case "DEVELOPER":
          this.dialogDeleteMember = true;
          break;
        case "OBSERVER":
          this.dialogDeleteMember = true;
          break;
      }
    },
    dialogCreateMemberEmit(Toggle) {
      this.dialogCreateMember = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    dialogChangeDeveloperLeadEmit(Toggle) {
      this.dialogChangeDeveloperLead = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    dialogChangeTestLeadEmit(Toggle) {
      this.dialogChangeTestLead = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
  },
};
</script>
