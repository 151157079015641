<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateMember :dialogCreateMember="dialogCreateMember" :StoreObj="StoreObj" :user_role="user_role" @clicked="dialogCreateMemberEmit" />
    <DeleteMember :dialogDeleteMember="dialogDeleteMember" :StoreObj="StoreObj" :getEachProjectDetails="getEachProjectDetails" @clicked="dialogDeleteMemberEmit" />
    <AddRemoveIssueApprover :dialogAddRemoveApprover="dialogAddRemoveApprover" :StoreObj="StoreObj" @clicked="dialogAddRemoveApproverEmit" />
    <v-card class="elevation-0">
      <v-row class="mx-2">
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-tabs v-model="membersTab">
              <v-tab class="text-capitalize">Owner/Leads</v-tab>
              <v-tab class="text-capitalize">Testers</v-tab>
              <v-tab class="text-capitalize">Developers</v-tab>
              <v-tab class="text-capitalize">Observers</v-tab>
            </v-tabs>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small color="primary" class="text-capitalize mt-2" @click="(dialogCreateMember = true), (StoreObj.action = 'CREATE')">Add</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <OwnerAndLeads v-if="membersTab == 0" />
        <v-data-table
          dense
          v-if="membersTab != 0"
          :fixed-header="true"
          :height="windowHeight"
          class="elevation-0"
          :headers="filteredMemberHeader"
          :items="GetAllUserDetailsList"
          :search="search"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [50, 100, 250, 500, 1000],
          }"
          :items-per-page="50"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllUserDetailsList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <div class="FontSize">
              {{ item.user_email_id }}
            </div>
          </template>
          <template v-slot:[`item.is_approver`]="{ item }">
            <div class="FontSize">
              <v-icon small color="primary" v-text="item.is_approver == false ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked'"></v-icon>
            </div>
          </template>
          <template v-slot:[`item.all_roles`]="{ item }">
            <div class="FontSize">
              {{ item.all_roles.join(", ") }}
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.user_role != 'TEST_LEAD' && item.user_role != 'DEV_LEAD' && item.user_role != 'TEST_LEAD_&_DEV_LEAD'"
                  icon
                  :color="'red'"
                  @click="CheckItem(item, 'DELETE', user_role)"
                >
                  <v-icon small v-text="'mdi-delete'"></v-icon>
                </v-btn>
              </template>
              <span>Delete {{ user_role.toLowerCase() }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="checkApproverRightsCondition(item) == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on" @click="CheckItem(item, 'APPROVER')">
                  <v-icon small v-text="item.is_approver == false ? 'mdi-account-star' : 'mdi-account-minus'"></v-icon>
                </v-btn>
              </template>
              <span v-text="item.is_approver == false ? 'Give Issue Approver Rights' : 'Remove Issue Approver Rights'"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateMember from "@/components/Members/Dialogs/CreateMember.vue";
import DeleteMember from "@/components/Members/Dialogs/DeleteMember.vue";
import AddRemoveIssueApprover from "@/components/Members/Dialogs/AddRemoveIssueApprover.vue";
import OwnerAndLeads from "@/components/Members/Cards/OwnerAndLeads.vue";
import { GetAllUsersDetails } from "@/mixins/GetAllUsersDetails.js";
export default {
  components: {
    AddRemoveIssueApprover,
    OwnerAndLeads,
    DeleteMember,
    CreateMember,
    Overlay,
  },
  mixins: [GetAllUsersDetails],
  data: () => ({
    search: "",
    membersTab: 0,
    windowHeight: 0,
    user_role: "TESTER",
    my_roles: [],
    StoreObj: {},
    dialogCreateMember: false,
    dialogDeleteMember: false,
    dialogAddRemoveApprover: false,
    GetAllUserDetailsList: [],
    memberHeaders: [
      {
        text: "Sl No.",
        value: "sl_no",
      },
      {
        text: "Email ID",
        value: "user_email_id",
      },
      {
        text: "Roles",
        value: "all_roles",
      },
      {
        text: "Issue Approver",
        value: "is_approver",
      },
      {
        text: "Action",
        value: "Action",
      },
    ],
    overlay: false,
    filteredMemberHeader: [],
  }),
  watch: {
    membersTab(value) {
      this.user_role = value == 1 ? "TESTER" : value == 2 ? "DEVELOPER" : value == 3 ? "OBSERVER" : "";
      if (this.membersTab != 0) {
        this.getHeaders();
        this.GetAllUsersDetailsMethod(this.user_role);
      }
    },
  },
  mounted() {
    this.my_roles = this.$store.getters.get_current_project_details.roles;
    this.windowHeight = window.innerHeight - 200;
  },
  methods: {
    checkApproverRightsCondition(item) {
      if (this.membersTab == 1 && item.user_role != "TEST_LEAD" && item.user_role != "TEST_LEAD_&_DEV_LEAD") return true;
      else return false;
      // if (this.user_role != "TESTER") return false;
      // else {
      //   if (this.my_roles && this.my_roles.length == 1 && this.my_roles[0] == "OWNER"){
      //     console.log("Coming Inside This!!!")
      //     return true;
      //   }
      //   else if (this.my_roles && this.my_roles.length > 1 && this.my_roles.includes("TEST_LEAD") == false) {
      //     return false;
      //   } else {
      //     if (item.user_role != "TEST_LEAD" && item.user_role != "TEST_LEAD_&_DEV_LEAD") return true;
      //     else return false;
      //   }
      // }
    },
    dialogCreateMemberEmit(Toggle) {
      this.dialogCreateMember = false;
      if (Toggle == 2) {
        this.GetAllUsersDetailsMethod(this.user_role);
      }
    },
    checkCondition() {
      if (this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("OWNER") == true) return true;
      else return false;
    },
    dialogAddRemoveApproverEmit(Toggle) {
      this.dialogAddRemoveApprover = false;
      if (Toggle == 2) {
        this.GetAllUsersDetailsMethod(this.user_role);
      }
    },
    dialogDeleteMemberEmit(Toggle) {
      this.dialogDeleteMember = false;
      if (Toggle == 2) {
        this.GetAllUsersDetailsMethod(this.user_role);
      }
    },
    CheckItem(item, action, user_role) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      switch (action) {
        case "DELETE":
          this.dialogDeleteMember = true;
          this.StoreObj.user_role = user_role;
          break;
        case "APPROVER":
          this.dialogAddRemoveApprover = true;
          break;
      }
    },
    getHeaders() {
      switch (this.user_role) {
        case "TESTER":
          if (this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("OWNER") == true) this.filteredMemberHeader = this.memberHeaders;
          else this.filteredMemberHeader = this.memberHeaders.filter((header) => header.value != "Action");
          break;
        case "DEVELOPER":
          if (this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("OWNER") == true) this.filteredMemberHeader = this.memberHeaders.filter((header) => header.value != "is_approver");
          else this.filteredMemberHeader = this.memberHeaders.filter((header) => header.value != "Action" && header.value != "is_approver");
          break;
        case "OBSERVER":
          if (this.my_roles.includes("TEST_LEAD") == true || this.my_roles.includes("DEV_LEAD") == true || this.my_roles.includes("OWNER") == true)
            this.filteredMemberHeader = this.memberHeaders.filter((header) => header.value != "is_approver");
          else this.filteredMemberHeader = this.memberHeaders.filter((header) => header.value != "Action" && header.value != "is_approver");
          break;
      }
      console.log("Check filteredMemberHeader", this.filteredMemberHeader);
      this.$forceUpdate();
    },
  },
};
</script>
