<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateMember">
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div>Add <span v-text="user_role_items.length > 1 ? 'Member' : user_role_items.length != 0 ? user_role_items[0].text : ''"></span></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreateMemberEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0 pt-4">
          <v-form ref="form">
            <div v-if="user_role_items.length > 1" class="FontSize">Member Role</div>
            <v-radio-group v-if="user_role_items.length > 1" row dense class="pa-0 ma-0" v-model="user_role" :rules="[(v) => !!v || 'Required']">
              <v-radio v-for="(role, role_idx) in user_role_items" :key="role_idx" :label="role.text" :value="role.value"></v-radio>
            </v-radio-group>
            <v-text-field
              dense
              multiple
              outlined
              class="FontSize field_height field_label_size"
              label="Enter Email ID"
              v-model="member_email"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogCreateMemberEmit(1)">Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateFormMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { addUsersToProject } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogCreateMember: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    user_role: "",
    user_role_items: [],
    loading: false,
    member_email: "",
    SnackBarComponent: {},
    project_details: {},
  }),
  watch: {
    dialogCreateMember(value) {
      if (value == true) {
        this.checkAddingMemberAccess();
      }
    },
  },
  methods: {
    async checkAddingMemberAccess() {
      this.user_role_items = [];
      let my_roles = await this.$store.getters.get_current_project_details.roles;
      if (my_roles.includes("OWNER") == true) {
        this.user_role_items = [
          { text: "Tester", value: "TESTER" },
          { text: "Developer", value: "DEVELOPER" },
          { text: "Observer", value: "OBSERVER" },
        ];
      } else if (my_roles.includes("DEV_LEAD") == true && my_roles.includes("TEST_LEAD") == true) {
        // this.user_role = "TESTER";
        this.user_role_items = [
          { text: "Tester", value: "TESTER" },
          { text: "Developer", value: "DEVELOPER" },
          { text: "Observer", value: "OBSERVER" },
        ];
      } else if (my_roles.includes("DEV_LEAD") == true && my_roles.includes("TEST_LEAD") == false) {
        // this.user_role = "DEVELOPER";
        this.user_role_items = [
          { text: "Developer", value: "DEVELOPER" },
          { text: "Observer", value: "OBSERVER" },
        ];
      } else if (my_roles.includes("DEV_LEAD") == false && my_roles.includes("TEST_LEAD") == true) {
        // this.user_role = "TESTER";
        this.user_role_items = [
          { text: "Tester", value: "TESTER" },
          { text: "Observer", value: "OBSERVER" },
        ];
      } else {
        this.user_role_items.push({ text: "Observer", value: "OBSERVER" });
      }
      this.user_role = this.user_role_items[0].value;
      this.$forceUpdate();
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        if (this.checkIfCondtionApproves() == true) {
          this.addMemberMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select An Email ID",
        };
      }
    },
    checkIfCondtionApproves() {
      let project_details = this.$store.getters.get_current_project_details;
      console.log("Coming Here", this.user_role);
      if (this.user_role == "OBSERVER") {
        if (project_details.tester_lead_email_id == this.member_email) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Test Lead",
          };
          return false;
        } else if (project_details.developer_lead_email_id == this.member_email) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Developer Lead",
          };
          return false;
        } else if (project_details.testers_email.includes(this.member_email) == true) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Tester",
          };
          return false;
        } else if (project_details.developers_email.includes(this.member_email) == true) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Developer",
          };
          return false;
        } else if (project_details.project_owner == this.member_email) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Project Owner Can't Be Added As An Observer",
          };
          return false;
        } else if (project_details.observers_email.includes(this.member_email) == true) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already An Observer",
          };
          return false;
        } else {
          return true;
        }
      }
      if (this.user_role == "TESTER") {
        if (project_details.tester_lead_email_id == this.member_email) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Test Lead",
          };
          return false;
        } else if (project_details.testers_email.includes(this.member_email) == true) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Tester",
          };
          return false;
        } else {
          return true;
        }
      }
      if (this.user_role == "DEVELOPER") {
        if (project_details.developer_lead_email_id == this.member_email) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Developer Lead",
          };
          return false;
        } else if (project_details.developers_email.includes(this.member_email) == true) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "The User Is Already Added As A Developer",
          };
          return false;
        } else {
          return true;
        }
      }
    },
    async addMemberMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
        user_role: this.user_role,
        [this.user_role == "TESTER" ? "testers_email" : this.user_role == "DEVELOPER" ? "developers_email" : "observers_email"]: this.member_email.trim(),
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(addUsersToProject, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.AddUsersToProject);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogCreateMemberEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogCreateMemberEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
